import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This article contains notes for the video from lesson 6 of `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCuXy5tCgEninup9cGplbiFw"
      }}>{`Missing Semester`}</a>{` on Version Control and git. I've taken notes of how git works and less about git commands and what they do. I hope these notes prove useful for you, at the bottom of the article you have some great references if you want to go more in-depth on your git learning.`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/2sjqTHE0zok" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <p>{`Version control systems are tools to keep track of changes to source code or other collections of files and folders. They help to track the changes of documents and they make it easy collaborating with someone else.`}</p>
    <p>{`It track changes in a series of snapshots and they contain the entire state of files or folders within a directory. They also add metadata that contains things like who created the snapshot, messages, changes, etc.`}</p>
    <p>{`Version control is useful because it let you look at old snapshots of a project, keep a log of changes, work on different branches, see who wrote a specific line or who added a change and more.`}</p>
    <h2 {...{
      "id": "git-data-model",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#git-data-model",
        "aria-label": "git data model permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Git data model`}</h2>
    <p>{`Git has a data model that enables features of version control such as keeping track of changes in history, allowing branches and collaboration with other folks.`}</p>
    <h3 {...{
      "id": "snapshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#snapshots",
        "aria-label": "snapshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Snapshots`}</h3>
    <p>{`A snapshot is what we know as a commit. It contains the history of files, folders and metadata. A snapshot is the top-level tree/directory that is being tracked.`}</p>
    <Note mdxType="Note">
  We can track a top-level tree with the command <code>git init</code>.
    </Note>
    <p>{`We can think of a snapshoot as being built like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`type commit = struct {
    parent: array<commit>
    author: string
    message: string
    snapshot: tree
}
`}</code></pre>
    <Note mdxType="Note">
This is pseudo-code
    </Note>
    <p>{`In Git terminology, a file is called a "blob" because it's just a bunch of bytes. We can think of blobs as an array of bytes:`}</p>
    <pre><code parentName="pre" {...{}}>{`type blob = array<bytes>
`}</code></pre>
    <p>{`A directory is called a tree. A tree, maps names to trees or blobs which means that a directory can contain other directories.`}</p>
    <pre><code parentName="pre" {...{}}>{`type tree = map<string, tree | blob>
`}</code></pre>
    <h3 {...{
      "id": "objects-and-content-addressing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#objects-and-content-addressing",
        "aria-label": "objects and content addressing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Objects and content-addressing`}</h3>
    <p>{`Even though we name each file, directory and commit differently, they are all objects. And git stores all this data as objects inside a content address store.`}</p>
    <pre><code parentName="pre" {...{}}>{`objects = map<string, objects>
`}</code></pre>
    <p>{`This means that the core of git is a simple key-value data store. You can insert any object into your git storage and git will store it with a unique key.`}</p>
    <p>{`This unique key is the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/SHA-1"
      }}>{`SHA-1 hash`}</a>{` of that object and it allows us to access the object with that key.`}</p>
    <Note mdxType="Note">
 Using a hash allows us to take a big piece of data and turn it into a short string
    </Note>
    <p>{`Taking the example from that `}<inlineCode parentName="p">{`git log`}</inlineCode>{` that I have shown before:`}</p>
    <pre><code parentName="pre" {...{}}>{`* 8f0dede (HEAD -> twitter, upstream/master, master) Add twitch to config example (#1653)
| * a03b68c (origin/twitch, twitch) Add twitch to config example
|/
* 2c9b684 Migrate helper and connector tests to pytest (#1648)
* af00ac9 Fix typo on documentation - Gitter (#1652)
`}</code></pre>
    <p>{`If we want to access or reference the snapshot `}<em parentName="p">{`"Migrate helper and connector tests to pytest"`}</em>{` I can reference the first seven characters of that object hash: `}<inlineCode parentName="p">{`af00ac9`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "git-branches",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#git-branches",
        "aria-label": "git branches permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Git Branches`}</h2>
    <p>{`We can look at snapshots as to what we call  `}<em parentName="p">{`commits`}</em>{` in git, they contain all the changes done inside the directory. Each snapshot/commit points back to the one that precedes it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/809384b0ff876f6e0ffacd81baa02528/a1277/main_branch.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "11.200000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAATUlEQVQI1z2MwQ2AMBDDmCEZofCCqtSHith/McQhkPLww/G0ON4VRxOnCNFETRiii11s4lLHlM9fHNNPs6M6RqqPbTBHhrpYs9tS+883NnsRrTlOtKIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "main branch",
            "title": "main branch",
            "src": "/static/809384b0ff876f6e0ffacd81baa02528/0eb09/main_branch.png",
            "srcSet": ["/static/809384b0ff876f6e0ffacd81baa02528/c59a0/main_branch.png 125w", "/static/809384b0ff876f6e0ffacd81baa02528/86700/main_branch.png 250w", "/static/809384b0ff876f6e0ffacd81baa02528/0eb09/main_branch.png 500w", "/static/809384b0ff876f6e0ffacd81baa02528/a1277/main_branch.png 565w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
Since all these snapshots point back to the previous one, we can this `}<em parentName="p">{`history/line`}</em>{` a branch. Usually, you will always have the main branch when you start tracking the changes of a folder with git.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ddc7dc757fad957ec5797e526537c65b/a1277/git_branch.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAk0lEQVQY04WQWw6CUAxE3YIzCzDyEogodwYRP9j/tgw10YgxJP3o6+Q03ZX0Kiq6h1qqoWu6hXrod62kd6s6pzt6gkZoghI0QPM+tTHahhdzODP6GMkAPaANuKLPdBFMRjdRZvSB7uKE6h+c0ykME3SDBN0jHyOu0Iw0fPs/cEG3tJZXLc4TfYlXNdGvY/S66w0/AeI3M93yJ+AuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "git branch",
            "title": "git branch",
            "src": "/static/ddc7dc757fad957ec5797e526537c65b/0eb09/git_branch.png",
            "srcSet": ["/static/ddc7dc757fad957ec5797e526537c65b/c59a0/git_branch.png 125w", "/static/ddc7dc757fad957ec5797e526537c65b/86700/git_branch.png 250w", "/static/ddc7dc757fad957ec5797e526537c65b/0eb09/git_branch.png 500w", "/static/ddc7dc757fad957ec5797e526537c65b/a1277/git_branch.png 565w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Usually, this branch is the one that folks use to keep the latest version of a project. The powerful thing of git, is that it allows you to branch off from a specific snapshot/commit, so you can work with different branches of a commit and then merge them.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f190afd834c41591262768ae9e8b42cd/708e8/gitcommit.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABX0lEQVQY00WPWW7bQBAFdQ+JtqyZnn1fOBwuojyxpBiwA+T+lwnIBAhQH/3RBbw6ROmugxUyqIvXYAlSF6acSIkPVEUkffBLzk3KgpACsIR4jA2A7TpymGK41f0j3qJfY1hTadqOMd+cm0v+YCwaUxHSpxOcTngHjke8yY9r+mq5L8/19mtaPlv7XeuzDo91/e77Zu1ozEiIB3BdR3b/H10Hh5rcfY7j8D6Wn1P5HMu973+Uch/H5zA8Srnn3Jyb/0JpANg2I6Tf3sThFSlAjGCGzhqfLUYaIb1VYXO5KAB3Pos90mFsMNYA9r9MlXVSaS4FjQycFpmxANxTEQixnCcpe0o9pUGpwnkkxCOkjke0zTbWPhdXY73aNutrwpkz/+o8sp6LpHU1ZpSyCNF7v2hdlSqMha4jLy/04EL5mELJU8wt5aZ0dX5xdo5h3TuXGLdDyk2OcVWqGFM5TwD2D58DV6F4XMnbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gitcommit",
            "title": "gitcommit",
            "src": "/static/f190afd834c41591262768ae9e8b42cd/0eb09/gitcommit.png",
            "srcSet": ["/static/f190afd834c41591262768ae9e8b42cd/c59a0/gitcommit.png 125w", "/static/f190afd834c41591262768ae9e8b42cd/86700/gitcommit.png 250w", "/static/f190afd834c41591262768ae9e8b42cd/0eb09/gitcommit.png 500w", "/static/f190afd834c41591262768ae9e8b42cd/e9985/gitcommit.png 750w", "/static/f190afd834c41591262768ae9e8b42cd/1263b/gitcommit.png 1000w", "/static/f190afd834c41591262768ae9e8b42cd/708e8/gitcommit.png 1218w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can see the log of a project that I work on. You can see that each `}<inlineCode parentName="p">{`*`}</inlineCode>{` is a snapshot, each line shows the `}<em parentName="p">{`path`}</em>{` that each snapshot took, you can also see the times that a branch was created and then merged back into the main one.`}</p>
    <h2 {...{
      "id": "references-and-repositories",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#references-and-repositories",
        "aria-label": "references and repositories permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`References and Repositories`}</h2>
    <p>{`Now that we know that git objects can be identified by their SHA-1 hash and that snapshots live inside branches we can explore more about what is a reference inside git. `}</p>
    <p>{`A reference is a human-readable label to refer to a particular snapshot in history instead of using the whole SHA-1 hash.`}</p>
    <pre><code parentName="pre" {...{}}>{`references = map<string, string>
`}</code></pre>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f190afd834c41591262768ae9e8b42cd/708e8/gitcommit.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABX0lEQVQY00WPWW7bQBAFdQ+JtqyZnn1fOBwuojyxpBiwA+T+lwnIBAhQH/3RBbw6ROmugxUyqIvXYAlSF6acSIkPVEUkffBLzk3KgpACsIR4jA2A7TpymGK41f0j3qJfY1hTadqOMd+cm0v+YCwaUxHSpxOcTngHjke8yY9r+mq5L8/19mtaPlv7XeuzDo91/e77Zu1ozEiIB3BdR3b/H10Hh5rcfY7j8D6Wn1P5HMu973+Uch/H5zA8Srnn3Jyb/0JpANg2I6Tf3sThFSlAjGCGzhqfLUYaIb1VYXO5KAB3Pos90mFsMNYA9r9MlXVSaS4FjQycFpmxANxTEQixnCcpe0o9pUGpwnkkxCOkjke0zTbWPhdXY73aNutrwpkz/+o8sp6LpHU1ZpSyCNF7v2hdlSqMha4jLy/04EL5mELJU8wt5aZ0dX5xdo5h3TuXGLdDyk2OcVWqGFM5TwD2D58DV6F4XMnbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gitcommit",
            "title": "gitcommit",
            "src": "/static/f190afd834c41591262768ae9e8b42cd/0eb09/gitcommit.png",
            "srcSet": ["/static/f190afd834c41591262768ae9e8b42cd/c59a0/gitcommit.png 125w", "/static/f190afd834c41591262768ae9e8b42cd/86700/gitcommit.png 250w", "/static/f190afd834c41591262768ae9e8b42cd/0eb09/gitcommit.png 500w", "/static/f190afd834c41591262768ae9e8b42cd/e9985/gitcommit.png 750w", "/static/f190afd834c41591262768ae9e8b42cd/1263b/gitcommit.png 1000w", "/static/f190afd834c41591262768ae9e8b42cd/708e8/gitcommit.png 1218w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let's look at the image from that `}<inlineCode parentName="p">{`git log`}</inlineCode>{` that I used before. You can see things such as:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`HEAD`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`upstream/master`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`master`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`refs/stash`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`origin/ibm-watson`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`...`}</inlineCode></li>
    </ul>
    <p>{`There are all references that reference something. The `}<inlineCode parentName="p">{`HEAD`}</inlineCode>{` means that this is the current commit that we are working on, `}<inlineCode parentName="p">{`master`}</inlineCode>{` refers to the main branch while `}<inlineCode parentName="p">{`upstream/master`}</inlineCode>{` is the main branch from the original repository for this project.`}</p>
    <p>{`So what is a repository?`}</p>
    <p>{`Putting very simply, a git repository contains all the objects and references. These repositories live inside our disk, but you could use services like GitHub, Gitlab or Bitbucket to save your repositories on these services. They also allow better collaboration with other folks and the reason why they become so popular.`}</p>
    <h2 {...{
      "id": "the-stating-area",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-stating-area",
        "aria-label": "the stating area permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Stating Area`}</h2>
    <p>{`The last thing that is worth to mention is that git has this concept of stating area. This is where you add files to this staging area to keep track of changes done and include them in your next snapshot.`}</p>
    <p>{`By using the command `}<inlineCode parentName="p">{`git status`}</inlineCode>{`, git tells you if you have any files that are currently untracked inside a project - these files will not be included on your next snapshot.`}</p>
    <p>{`To include these files you can run the command `}<inlineCode parentName="p">{`git add <name of the file>`}</inlineCode>{` or `}<inlineCode parentName="p">{`git add .`}</inlineCode>{` to include all untracked files into the staging area.`}</p>
    <p>{`After running that command you can type `}<inlineCode parentName="p">{`git status`}</inlineCode>{` again and you will see your file names in green and bellow the text: `}<em parentName="p">{`Changes to be committed:`}</em></p>
    <p>{`To create a new snapshot with these files in staging you need to run the command `}<inlineCode parentName="p">{`git commit`}</inlineCode>{` which will open your text editor for you to choose a name for that snapshot. Alternatively, you can run the command `}<inlineCode parentName="p">{`git commit -m "<message>"`}</inlineCode>{` to do it in a single command.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`References:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://missing.csail.mit.edu/2020/version-control/"
        }}>{`Official course notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://includejs.dev/notes/missing-semester-06/"
        }}>{`IncludeJS: Eva's lesson notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alexwlchan.net/a-plumbers-guide-to-git/1-the-git-object-store/"
        }}>{`The git object store`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://git-scm.com/book/en/v2"
        }}>{`Book: Pro Git - Free`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jwiegley.github.io/git-from-the-bottom-up/"
        }}>{`Git from the Bottom up`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      